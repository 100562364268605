<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <h1 style="display: none;">{{ $t('general.searchAgent') }}</h1>
    <h2 style="display: none;">{{ $t('general.searchAgent') }}</h2>
    <section class="container">
      <agent />
    </section>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const Agent = () => import('@/components/search-agent/agent');

import { mapState } from 'vuex';
export default {
  async asyncData({ store, route, query }) {
    console.log(
      'Panggil get search agent dari asyncData di search-agent.vue dengan isi route: ',
      route,
    );

    store.commit('global/SET_LOADING', true);
    store.commit('v2/search/SET_AGENTS', []);

    await store.dispatch('v2/search/getAgents', {
      query: query,
      params: route.params,
    });
    store.commit('global/SET_LOADING', false);

    const baseUrl = store.state.global.baseUrl;
    let title = 'Searching for all agents at Propfix.';
    // if (title.length > 52) title = title.substring(0, 49) + '...';
    title += ' | Propfix';

    let description =
      'Searching for all agents registered with Propfix. We partnered with various agents to help you find the best property to rent and buy/sell in Singapore.';
    if (description.length > 307) description = description.substring(0, 304) + '...';
    description += ' | Propfix';

    const head = {
      title: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
        {
          hid: 'og-description',
          property: 'og:description',
          content: description,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: baseUrl + route.fullPath,
          id: 'canonical',
        },
      ],
    };
    store.commit('head/SET_CONTENT', head);
  },
  beforeDestroy() {
    this.$store.commit('v2/search/SET_SEARCH_TERM', '');
  },
  components: {
    Agent,
    DefaultLayout,
  },
  computed: {
    ...mapState({
      agents: state => state.v2.search.agents,
      meta: state => state.v2.search.metaAgent,
      isLoading: state => state.global.isLoading,
    }),
    breadcrumbs() {
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.$t('breadcrumb.searchAgent'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
};
</script>
